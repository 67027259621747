import { Grid, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import Button from "@restart/ui/esm/Button";
import React from "react";
import aboutImg from "../../images/DUBAI FZE.jpg";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "300px",
  WebkitBorderRadius: "5px",
});

const About = () => {
  return (
    <Box sx={{ m: 3, p: 3 ,my:10 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
        sx={{ display: "flex" }}
      >
        <Grid item xs={4} sm={8} md={6} lg={8}>
          <Typography sx={{ color: "#ff6666", fontWeight: 700 }} variant="h4">
            ABOUT US
          </Typography>
          <Typography sx={{ color: " #4d4d00", py: 1 }} variant="body1">
          About Us
          Advanced Facility Services LLC is a premier provider of comprehensive facility management solutions, dedicated to enhancing the efficiency, safety, and aesthetic appeal of commercial and residential properties. With a commitment to excellence and a passion for delivering top-notch services, we have established ourselves as a trusted partner for a diverse range of clients.
          </Typography>
          <Button className="purchase-btn"  variant="button">
            {" "}
            Read More
          </Button>
        </Grid>
        <Grid item xs={4} sm={8} md={6} lg={8}>
          <Img alt="complex" src={aboutImg} />

         
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
